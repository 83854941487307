<template>
	<div v-if="loaded">
		<v-row>
			<v-col
				cols="12"
				xl="4"
				lg="4"
				md="12"
				sm="12"
				xs="12"
				style="height: calc(100vh - 65px);"
			>
				<div class="h-80" style="overflow-y: auto; overflow-x: hidden;">
					<v-row dense class="m-0 p-0">
						<v-col cols="12" xl="10" class="p-0 text-center">
							<h6 class="font-weight-semibold m-0">{{ establishment.name }}</h6>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" xl="12">
							<el-input
								type="text"
								v-model="form.name"
								autocomplete="off"
								required
								placeholder="Nombre de zona"
							>
							</el-input>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" xl="6" lg="12" md="12" sm="12">
							<el-input
								v-model="form.shipping_fee"
								placeholder="Costo de envío"
								autocomplete="off"
								required
							>
							</el-input>
						</v-col>
						<v-col cols="12" xl="6" lg="12" md="12" sm="12">
							<el-select
								v-model="form.send_free"
								:items="items"
								placeholder="Envío gratuito"
							>
								<el-option
									v-for="(item, index) in items"
									:key="index"
									:label="item.text"
									:value="item.value"
								></el-option>
							</el-select>
						</v-col>
					</v-row>
					<v-row dense v-if="form.send_free">
						<v-col cols="12" xl="12" lg="12" md="12" sm="12">
							<el-input
								v-model="form.cost_free"
								placeholder="Envío gratuito por monto superior a:"
								autocomplete="off"
								required
							>
							</el-input>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" xl="12" lg="12" md="12" sm="12" class="p-0">
							<p class="text-center">
								Puede modificar su zona de reparto moviendo los puntos en el mapa.
							</p>
						</v-col>
					</v-row>
				</div>
				<div class="h-20" style="overflow-y: auto; overflow-x: hidden;">
					<v-divider class="my-4"></v-divider>
					<v-row dense>
						<v-col cols="12" xl="12" lg="12" class="text-center">
							<v-btn color="secondary" @click.prevent="submit">Guardar cambios</v-btn>
						</v-col>
					</v-row>
				</div>
			</v-col>
			<v-col
				cols="12"
				xl="8"
				lg="8"
				md="12"
				sm="12"
				class="pt-3 hyo"
				id="map"
			>
				<div style="height: calc(84vh - 110px);"></div>

				<!-- <v-row>
                    <v-col cols="12" xl="4">
                        <v-btn color="secondary" block @click="clickPayment" :loading="loadingSubmit">GUARDAR</v-btn>
                    </v-col>
                </v-row> -->
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loaded: false,
			loadingSubmit: false,
			resource: 'distribution-areas',
			establishment: null,
			form: {},
			errors: {},
			items: [
				{ value: false, text: 'No' },
				{ value: true, text: 'Si' },
			],
			latitude: null,
			longitude: null,
			polygon: {},
			defaultCoords: [
				{ lat: -11.81550829492178, lng: -77.13921132206916 },
				{ lat: -11.8151722462836, lng: -77.12410512089728 },
				{ lat: -11.819120791739543, lng: -77.11698117375373 },
				{ lat: -11.821179053496568, lng: -77.10805478215217 },
				{ lat: -11.828571866049314, lng: -77.09947171330451 },
				{ lat: -11.835670458277718, lng: -77.09827008366584 },
				{ lat: -11.844868951553439, lng: -77.09895672917365 },
				{ lat: -11.845666979751881, lng: -77.11371960759162 },
				{ lat: -11.845792983991155, lng: -77.13140072941779 },
				{ lat: -11.838190624211101, lng: -77.14178624272346 },
			],
		};
	},
	async created() {
		await this.initForm();
		await this.getEstablishment();
		await this.getRecord();
		await this.initMap();
	},

	methods: {
		initForm() {
			this.form = {
				name: null,
				shipping_fee: 0,
				send_free: false,
				cost_free: 0,
				state: true,
				coordinates: [],
			};
		},
		async getRecord() {
			await this.$http.get(`/${this.resource}/record`).then((response) => {
				if (response.data.data.id) {
					this.form = response.data.data;
				} else {
					this.form.coordinates = this.defaultCoords;
				}
				this.loaded = true;
			});
		},
		async getEstablishment() {
			await this.$http.get(`/${this.resource}/establishment`).then((response) => {
				this.establishment = response.data;
			});
		},
		initMap() {
			this.latitude = this.establishment.latitude;
			this.longitude = this.establishment.longitude;

			let map = new window.google.maps.Map(document.getElementById('map'), {
				center: new window.google.maps.LatLng(this.latitude, this.longitude),
				fullscreenControl: false,
				streetViewControl: false,
				mapTypeControl: false,
				zoomControl: false,
				zIndex: 0,
				zoom: 15,
			});

			let marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(this.latitude, this.longitude),
				map: map,
				// icon: imagenSVG
			});

			this.polygon = new window.google.maps.Polygon({
				paths: this.form.coordinates,
				strokeColor: '#1a333c',
				strokeOpacity: 1,
				strokeWeight: 2,
				fillColor: '#1a333c',
				fillOpacity: 0.15,
				editable: true,
				geodesic: true,
			});
			this.polygon.setMap(map);

			marker.setMap(map);

			// let newCoordenates = [];
			// map.addListener('click', function (e) {
			//     let clickPosition = e.latLng;
			//     newCoordenates.push({
			//         lat: clickPosition.lat(),
			//         lng: clickPosition.lng()
			//     })
			//     console.log(JSON.stringify(newCoordenates));
			// })

			let newCoordenates = [];
			map.addListener('click', function(e) {
				let clickPosition = e.latLng;
				newCoordenates.push({
					lat: clickPosition.lat(),
					lng: clickPosition.lng(),
				});
			});

			// window.google.maps.event.addListener(this.polygon.getPath(), 'insert_at', function(index, obj) {
			//     //polygon object: yourPolygon
			//     console.log(obj, 'insert_at');
			// });
			// window.google.maps.event.addListener(this.polygon.getPath(), 'set_at', function(index, obj) {
			//     //polygon object: yourPolygon

			//     console.log(obj, 'set_at');
			// });

			// let maker_select_8 = document.getElementById('local_maker_8')
			// window.google.maps.event.addDomListener(maker_select_8, "click", function () {
			//     map.setZoom(15);
			//     map.setCenter(marker.getPosition());
			// });
		},
		async submit() {
			let coordinates = [];
			let coords = this.polygon.getPath();
			for (let index = 0; index < coords.getLength(); index++) {
				let coord = coords.getAt(index);

				coordinates.push({
					lat: coord.lat(),
					lng: coord.lng(),
				});
			}

			this.form.coordinates = coordinates;
			this.loadingSubmit = false;
			await this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.getRecord();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors.message;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loadingSubmit = false;
				});
		},
		setCoordinates() {
			// let vetices = bermudaTriangle_8.getPath();
			// console.log(vetices.getAt(0).lat());
		},
	},
};
</script>
